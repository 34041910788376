import React, { FC } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container, Link, Toolbar, Typography } from "@mui/material";

import PlantingRequestFlow from "./PlantingRequestFlow";
import Topbar from "./Topbar";
import appTheme from "../theme";

const AppContainer: FC = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Topbar />
        <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
            <PlantingRequestFlow />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AppContainer;
