import React, { useEffect, useState, FC, MouseEventHandler } from "react";
import {
  Button,
  Box,
  Container,
  Fab,
  Link,
  Paper,
  Step,
  Stepper,
  StepLabel,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./utilities/useLocalStorage";
import {
  createTreePlantingRequest,
  PlantingRequest,
  Tree,
} from "../api/airtable";

import TreeAttributesForm from "./Step1TreeAttributes";
import LocationForm from "./Step2Location";
import Review from "./Step3Review";
import ImageCarousel from "./ImageCarousel";

const Copyright: FC = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://intn.city/">
        INTNCITY
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const steps = ["Tree species", "Location details", "Review your request"];

// 1247 80th Ave., Oakland, CA 37.755443, -122.184389
const EAST_OAKLAND: google.maps.LatLngLiteral = {
  lat: 37.755443,
  lng: -122.184389,
};

interface Image {
  url: string;
}

const PlantingRequestFlow: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useLocalStorage<number>("appStep", 0);
  const [email] = useLocalStorage("email", "");
  const [images, setImages] = useLocalStorage<Image[]>("images", []);
  const [notes, setNotes] = useLocalStorage("notes", "");
  const [appId, setAppId] = useLocalStorage("appUuid", "");
  const [tree, setTree] = useLocalStorage<Tree | null>("tree", null);
  const [currentLocation, setCurrentLocation] = useLocalStorage(
    "location",
    EAST_OAKLAND
  );
  const [currentAddress, setCurrentAddress] = useLocalStorage("address", "");
  const [complyAppropriateSpecies, setComplyAppropriateSpecies] =
    useLocalStorage("complyAppropriateSpecies", false);
  const [complyMinContainerSize, setComplyMinContainerSize] = useLocalStorage(
    "complyMinContainerSize",
    false
  );
  const [complyWithStandard, setComplyWithStandard] = useLocalStorage(
    "complyWithStandard",
    false
  );
  const [validated, setValidated] = useState(validateForm());

  useEffect(() => {
    setValidated(validateForm);
  }, [
    complyAppropriateSpecies,
    complyMinContainerSize,
    complyWithStandard,
    tree,
  ]);

  const StepContent: FC<{ step: number }> = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <TreeAttributesForm
            tree={tree}
            complyAppropriateSpecies={complyAppropriateSpecies}
            complyMinContainerSize={complyMinContainerSize}
            complyWithStandard={complyWithStandard}
            handleAttributesChanged={onHandleAttributesChanged}
          />
        );
      case 1:
        return (
          <LocationForm
            currentLocation={currentLocation}
            currentAddress={currentAddress}
            handleLocationChanged={onHandleLocationChanged}
          />
        );
      case 2:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  };

  function validateForm(): boolean {
    return (
      complyAppropriateSpecies &&
      complyMinContainerSize &&
      complyWithStandard &&
      !!tree
    );
  }

  function onHandleLocationChanged(
    address: string,
    loc: google.maps.LatLngLiteral
  ) {
    setCurrentAddress(address);
    setCurrentLocation(loc);
  }

  function onHandleAttributesChanged(
    key: string,
    value: boolean | Tree | null
  ) {
    if (typeof value === "boolean") {
      if (key === "complyAppropriateSpecies") {
        setComplyAppropriateSpecies(value);
        console.log("complyAppropriateSpecies = ", value);
      }

      if (key === "complyMinContainerSize") {
        setComplyMinContainerSize(value);
        console.log("setComplyMinContainerSize = ", value);
      }

      if (key === "complyWithStandard") {
        setComplyWithStandard(value);
        console.log("setComplyWithStandard = ", value);
      }
    }

    if (key === "tree") {
      if (typeof value === "boolean") {
        throw new Error("Tree is not selected");
      }
      setTree(value);
    }
  }

  async function handleNext() {
    setActiveStep(activeStep + 1);

    // final step of the application process, time to submit
    const submitForm = activeStep === steps.length - 1;
    if (submitForm) {
      let temp = localStorage.getItem("address");
      let address: string | null = null;
      if (temp) {
        address = temp;
      }
      temp = localStorage.getItem("tree");
      let tree: Tree | null = null;
      if (temp) {
        tree = JSON.parse(temp) as Tree;
      }
      temp = localStorage.getItem("location");
      let location: google.maps.LatLngLiteral | null = null;
      if (temp) {
        location = JSON.parse(temp);
      }
      temp = localStorage.getItem("images");
      let images: Image[] = [];
      if (temp) {
        images = JSON.parse(temp);
      }
      temp = localStorage.getItem("notes");
      let questions = "";
      if (temp) {
        questions = JSON.parse(temp);
      }
      if (!address) {
        throw new Error("Address is not selected");
      }
      if (!tree) {
        throw new Error("Tree is not selected");
      }
      if (!location) {
        throw new Error("Location is not selected");
      }
      if (!images.length) {
        throw new Error("Images are not selected");
      }

      const pr: PlantingRequest = {
        email: email,
        address: address,
        latitude: location.lat,
        longitude: location.lng,
        treeid: tree.id,
        quantity: 1,
        questions: questions,
        images: images,
      };
      console.log("Tree request: " + JSON.stringify(pr));

      // Create request in Airtable
      const requestId = await createTreePlantingRequest(pr);

      // Send confirmation email
      const shortId = requestId.slice(0, 4);
      const url =
        "/api/send-email?" +
        new URLSearchParams({
          to: email,
          app_id: shortId,
        });

      console.log("Sending email using URL: " + url);
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });
      const result = await response.json();
      console.log("Email result: " + JSON.stringify(result));

      setAppId(requestId);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleNewTreeRequest: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    // Force application state reset
    setImages([]);
    setActiveStep(0);
    setComplyAppropriateSpecies(false);
    setComplyMinContainerSize(false);
    setComplyWithStandard(false);
    setCurrentAddress("");
    setCurrentLocation(EAST_OAKLAND);
    setNotes("");

    navigate("/app");
  };

  console.log("tree", tree);
  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography variant="h6">
              Your application number is #{appId.slice(0, 4)}. We have emailed
              your application confirmation, and will send you an update when
              your permit has been approved.
            </Typography>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={handleNewTreeRequest}
                variant="contained"
                color="secondary"
                sx={{ width: 200, mt: 4 }}
              >
                Plant another Tree
              </Button>
            </Box>
            <ImageCarousel sx={{ width: "800px" }} />
          </>
        ) : (
          <>
            <StepContent step={activeStep} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <Fab
                size="medium"
                color="primary"
                aria-label="next"
                variant="extended"
                onClick={handleNext}
                disabled={!validated}
                sx={{ mt: 3, px: 4 }}
              >
                {activeStep === steps.length - 1
                  ? "Submit Application"
                  : "Next"}
              </Fab>
            </Box>
          </>
        )}
      </Paper>
      <Copyright />
    </Container>
  );
};

export default PlantingRequestFlow;
