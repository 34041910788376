import React, { useEffect, useState, FC } from "react";
import { useLocalStorage } from "./utilities/useLocalStorage";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { Dashboard } from "@uppy/react";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/webcam/dist/style.css";

interface Image {
  url: string;
}

const UppyUploadWidget: FC = () => {
  const [images, setImages] = useLocalStorage<Image[]>("images", []);
  const [uppy, setUppy] = useState<Uppy>();

  useEffect(() => {
    const uppy = new Uppy({
      meta: { type: "avatar" },
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: 3,
        allowedFileTypes: ["image/*"],
      },
      autoProceed: true,
    })
      .use(ImageEditor, {
        id: "ImageEditor",
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
      })
      .use(Webcam, {
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: false,
        modes: ["picture"],
        mirror: true,
        videoConstraints: {
          facingMode: "environment",
          width: { min: 720, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: false,
        locale: {
          strings: {
            // Add your custom strings here
            allowAccessTitle: "Allow access to your camera",
            allowAccessDescription:
              "In order to take pictures with your camera, please allow camera access for this site.",
            // ... other custom strings
          },
        },
      })
      .use(Tus, {
        endpoint: "https://tusd.tusdemo.net/files/",
      })
      .on("complete", (result) => {
        const attachments: Image[] = result.successful.map((item) => ({
          url: item.uploadURL,
        }));

        console.log("Image attachments: ", JSON.stringify(attachments));
        setImages(attachments);
      });
    setUppy(uppy);
    return () => {
      uppy.close();
    };
  }, []);

  if (!uppy) {
    return <>loading...</>;
  }

  return (
    <Dashboard
      id="ImageUploader"
      plugins={["Webcam", "ImageEditor", "FileInput"]}
      uppy={uppy}
      width={"100%"}
      // doneButtonHandler={undefined}
      proudlyDisplayPoweredByUppy={false}
    />
  );
};

export default UppyUploadWidget;
