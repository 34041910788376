import React, { useState, CSSProperties, FC } from "react";
import Carousel from "react-material-ui-carousel";
import Banner from "./Banner";

interface Item {
  Name: string;
  Caption: string;
  contentPosition: "left" | "middle" | "right";
  Items: {
    Name: string;
    Image: string;
  }[];
}

const items: Item[] = [
  {
    Name: "Climate Impact",
    Caption: "2022",
    contentPosition: "left",
    Items: [
      {
        Name: "145 Tons CO2 Removed",
        Image: "https://source.unsplash.com/featured/?factory",
      },
      {
        Name: "1,743 Trees Planted",
        Image: "https://source.unsplash.com/featured/?planting",
      },
    ],
  },
  {
    Name: "City Canopy",
    Caption: "36% coverage",
    contentPosition: "middle",
    Items: [
      {
        Name: "+4% Since 2020",
        Image: "https://source.unsplash.com/featured/?garden",
      },
      {
        Name: "Ozone Restoration",
        Image: "https://source.unsplash.com/featured/?traffic",
      },
    ],
  },
  {
    Name: "Volunteering",
    Caption: "Earth Day",
    contentPosition: "right",
    Items: [
      {
        Name: "Earth Day 2022",
        Image: "https://source.unsplash.com/featured/?whale",
      },
      {
        Name: "Plant-a-thon 2022",
        Image: "https://source.unsplash.com/featured/?marathon",
      },
    ],
  },
];
interface ImageCarouselProps {
  sx?: CSSProperties;
}

const ImageCarousel: FC<ImageCarouselProps> = ({ sx }) => {
  const [autoPlay, setAutoPlay] = useState(true);
  const [animation, setAnimation] = useState<"fade" | "slide">("fade");
  const [indicators, setIndicators] = useState(true);
  const [timeout, setTimeout] = useState(500);
  const [navButtonsAlwaysVisible, setNavButtonsAlwaysVisible] = useState(false);
  const [navButtonsAlwaysInvisible, setNavButtonsAlwaysInvisible] =
    useState(false);
  const [cycleNavigation, setCycleNavigation] = useState(true);

  return (
    <div style={{ ...sx, marginTop: "50px", color: "#494949" }}>
      <Carousel
        className="Example"
        autoPlay={autoPlay}
        animation={animation}
        indicators={indicators}
        duration={timeout} // Changed 'duration' to 'timeout' to match your state
        cycleNavigation={cycleNavigation}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
        next={(now, previous) =>
          console.log(
            `Next User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        prev={(now, previous) =>
          console.log(
            `Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        onChange={(now, previous) =>
          console.log(
            `OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        // Uncomment and customize below props as needed
        // fullHeightHover={false}
        // navButtonsProps={{ style: { backgroundColor: 'cornflowerblue', borderRadius: 0 } }}
        // navButtonsWrapperProps={{ style: { bottom: '0', top: 'unset' } }}
        // indicatorContainerProps={{ style: { margin: '20px' } }}
        // NextIcon='next'
      >
        {items.map((item, index) => (
          <Banner
            item={item}
            key={index}
            contentPosition={item.contentPosition}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
