import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Airtable, { Record, RecordData } from "airtable";

const API_KEY = "keyXYRXQVx9uUgYSX";
const BASE_ID = "appTdrMAIaYDa7one";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: API_KEY,
});
const base = Airtable.base(BASE_ID);
export interface Tree {
  id: string;
  approved: boolean;
  name: string;
  botanical: string;
  minTreeWellSize: string;
  height: string;
  foliage: string;
  image: string | null;
  notes: string;
}
const TreeListTable = "Tree List";
// Custom React Hook for fetching Tree List from Airtable
export function useTreeList() {
  const [treeList, setTreeList] = useState<Tree[] | null>(null);

  async function fetchTreeList() {
    const result = await new Promise<any[]>((resolve, reject) => {
      let trees: any[] = [];
      base(TreeListTable)
        .select({
          maxRecords: 2000,
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            console.log("records", records);
            // This function (`page`) will get called for each page of records.
            trees = trees.concat(records);

            fetchNextPage();
          },
          function done(err) {
            if (err) {
              reject(err);
              console.error(err);
              return;
            } else {
              resolve(
                trees.map((p: any) => {
                  return {
                    id: p.id,
                    approved: p.fields["Approved Street Tree"] == "Yes",
                    name: p.fields["Common Name"],
                    botanical: p.fields["Botanical Name*"],
                    minTreeWellSize: p.fields["Minimum Tree-Well Size"],
                    height: p.fields["Approximate Height"],
                    foliage: p.fields["Foliage (Deciduous or Evergreen) "],
                    image: p.fields["Image"] ? p.fields["Image"][0].url : null,
                    notes: p.fields["Notes"],
                  };
                })
              );
            }
          }
        );
    });

    setTreeList(result);
  }

  return [treeList, fetchTreeList] as const;
}

export interface PlantingRequest {
  email: string;
  address: string;
  latitude: number;
  longitude: number;
  treeid: string;
  quantity: number;
  questions: string;
  images: any[]; // Specify the type of items in the array, avoid using any
}
const TreeRequestTable = "Tree Request";
export async function createTreePlantingRequest(
  pr: PlantingRequest
): Promise<string> {
  let requestId = uuidv4();
  base(TreeRequestTable).create(
    [
      {
        fields: {
          "Request ID": requestId,
          "Request Date": Date.now(),
          "Requestor Email": pr.email,
          "Requested Tree": [pr.treeid],
          "Location Longitude": pr.longitude,
          "Location Latitude": pr.latitude,
          Questions: pr.questions,
          Status: "Request Received",
          "Location Image": pr.images,
        },
      },
    ],
    function done(err: any, records?: any) {
      if (err) {
        console.error(err);
        return;
      } else {
        console.log("success!", records);
        let appId = "";
        records?.forEach((record: any) => {
          appId = record.fields["Request ID"];
          console.log("Created request ID: " + appId);
        });
      }
    }
  );
  return requestId;
}
