import React, { useEffect, useState, FC } from "react";
import {
  Autocomplete,
  Avatar,
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  UseAutocompleteProps,
} from "@mui/material";

import { useTreeList } from "../api/airtable";
import TreeIcon from "../assets/tree.svg";
import { Tree } from "../api/airtable";

export interface ITreeSelector {
  tree: Tree | null;
  onPlantSelectionChanged: (plant: Tree | null) => void;
}
const TreeSelector: FC<ITreeSelector> = ({ tree, onPlantSelectionChanged }) => {
  const [plants, fetchTreeList] = useTreeList();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function onInitialize() {
      await fetchTreeList();
      setIsLoaded(true);
    }
    onInitialize();
  }, []);

  const handlePlantSelectionChange: UseAutocompleteProps<
    Tree,
    undefined,
    undefined,
    undefined
  >["onChange"] = (event, value, reason) => {
    let selection = value;
    // let selections =  plants.filter(p => p.sku == value);
    // console.log("Product selection: " + JSON.stringify(selection[0]));
    onPlantSelectionChanged(selection);
  };

  return (
    <Paper sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="h6">Tree species</Typography>
        </Grid>
        {isLoaded ? (
          <React.Fragment>
            <Grid xs={12} item>
              <Autocomplete
                options={plants || []}
                autoHighlight
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <li {...props} key={option.id}>
                    <Avatar
                      variant="rounded"
                      src={option.image ? option.image : TreeIcon}
                      sx={{ m: 1, width: 32, height: 32 }}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => {
                  console.log("params", params);
                  return (
                    <TextField
                      variant="outlined"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  );
                }}
                value={tree && typeof tree !== "boolean" ? tree : null}
                onChange={handlePlantSelectionChange}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item xs={12}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={!isLoaded}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
export default TreeSelector;
