import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./Hooks";
interface Props {
  children: ReactNode;
}
const ProtectedRoute: FC<Props> = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
