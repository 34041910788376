import React, { FC } from "react";
import TreeMap, { Props } from "./TreeMap";

const LocationForm: FC<Props> = (props) => {
  return (
    <React.Fragment>
      <TreeMap
        currentLocation={props.currentLocation}
        currentAddress={props.currentAddress}
        handleLocationChanged={props.handleLocationChanged}
      />
    </React.Fragment>
  );
};

export default LocationForm;
