import React, { FC, ChangeEventHandler } from "react";
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocalStorage } from "./utilities/useLocalStorage";
import { Tree } from "../api/airtable";

import UppyUploadWidget from "./ImageUploadUppy";

const Review: FC = () => {
  const [email] = useLocalStorage("email", "");
  const [tree] = useLocalStorage<Tree | null>("tree", null);
  const [address] = useLocalStorage("address", "");
  const [notes, setNotes] = useLocalStorage("notes", "");

  const plantingDetails = "Location: " + address;

  const handleChangeNotes: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setNotes(event.target.value);
  };
  if (!tree) {
    return <Stack spacing={2}>Hmmm seems like something went wrong...</Stack>;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Your application summary
      </Typography>
      <Card sx={{ px: 4, py: 2 }} color="info">
        <List disablePadding>
          <ListItem key={tree.id}>
            <ListItemText
              primary={tree.name}
              secondary={plantingDetails}
              primaryTypographyProps={{ fontSize: "18px", fontWeight: "bold" }}
            />
            <Typography variant="h5">1</Typography>
          </ListItem>
        </List>
      </Card>
      <Typography variant="h6" gutterBottom>
        Please provide a photo of where you plan to plant your tree.
      </Typography>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <UppyUploadWidget />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h6" gutterBottom>
        Questions
      </Typography>
      <TextField
        label="Do you have any questions or is there anything else you want to relay?"
        multiline
        rows={5}
        variant="standard"
        onChange={handleChangeNotes}
        value={notes}
      />
    </Stack>
  );
};

export default Review;
