import React, {
  createContext,
  useContext,
  useMemo,
  FC,
  ReactNode,
} from "react";
import { useLocalStorage } from "../utilities/useLocalStorage";
// 1. Type the AuthContext
interface AuthContextProps {
  user: string | null;
  Login: (email: string, password: string) => Promise<boolean>;
  Logout: () => void;
}
const AuthContext = createContext<AuthContextProps | null>(null);
interface Props {
  children: ReactNode;
}
export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useLocalStorage<string | null>("user", null);

  // call this function when you want to authenticate the user
  const Login = async (email: string, password: string): Promise<boolean> => {
    // TODO: actually authentication user, for now just let it pass if email contains "admin@intncity.com" and password is "admin"
    console.log(`login user: ${email}, ${password}`);
    let authenticated =
      (email === "admin@intncity.com" || email === "admin@intn.city") &&
      password === "IntnCity2@22";
    if (authenticated) {
      setUser(email);
      return true;
    } else {
      return false;
    }
  };

  // call this function to sign out logged in user
  const Logout = () => {
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      Login,
      Logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
