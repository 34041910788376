import React, { useState, useCallback, FC, CSSProperties } from "react";
import { Input, Typography, Stack } from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete,
  GoogleMapProps,
  LoadScriptProps,
  // MarkerProps,
  AutocompleteProps,
} from "@react-google-maps/api";

const mapContainerStyle: CSSProperties = {
  height: "450px",
  width: "100%",
};

const mapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  zoomControl: true,
  fullscreenControl: false,
  // mapTypeControl: false,
  mapTypeId: "satellite",
  styles: [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};
export interface Props {
  currentLocation: google.maps.LatLngLiteral;
  currentAddress: string;
  handleLocationChanged: (
    address: string,
    location: google.maps.LatLngLiteral
  ) => void;
}
const TreeMap: FC<Props> = ({
  currentAddress,
  currentLocation,
  handleLocationChanged,
}) => {
  const [Libraries] = useState<LoadScriptProps["libraries"]>(["places"]);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [mapMarker, setMapMarker] =
    useState<google.maps.LatLngLiteral>(currentLocation);
  const [address, setAddress] = useState<string>(currentAddress);
  const [addressAutocomplete, setAddressAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  // Initialize Google map instance
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyByT6Sov6C1_WUCU0HtUiYrEuuaDxL5VFs",
    libraries: Libraries,
  });

  const onMapLoad: GoogleMapProps["onLoad"] = useCallback(
    (map: google.maps.Map) => {
      setMapInstance(map);
      console.log("Map script loaded");
    },
    []
  );

  const onMapUnmount: GoogleMapProps["onUnmount"] = useCallback(() => {
    setMapInstance(null);
  }, []);

  const onAutocompleteLoad: AutocompleteProps["onLoad"] = (autocomplete) => {
    setAddressAutocomplete(autocomplete);
  };

  const onPlaceChanged: AutocompleteProps["onPlaceChanged"] = () => {
    console.log("onPlaceChanged");
    if (addressAutocomplete) {
      const place = addressAutocomplete.getPlace();
      // if (place.formatted_address) {
      //   setCurrentAddress(place.formatted_address);
      // }
      const loc = place.geometry?.location;

      if (mapInstance && loc) {
        console.log("New map marker on: " + JSON.stringify(loc));
        setMapMarker(loc.toJSON());
        mapInstance.panTo(loc);
        mapInstance.setZoom(19);
      }

      if (place.formatted_address) {
        if (loc) {
          handleLocationChanged(place.formatted_address, loc.toJSON());
        } else {
          console.error("handleLocationChanged: loc is undefined");
        }
      } else {
        console.error(
          "handleLocationChanged: place.formatted_address is undefined"
        );
      }
    }
  };

  // const onMarkerDragEnd: MarkerProps["onDragEnd"] = (e) => {
  //   const loc = e.latLng?.toJSON();
  //   const address = e.latLng?.toString();
  //   if (loc && address) {
  //     handleLocationChanged(address, loc);
  //     setAddress(address);
  //     setMapMarker(loc);
  //   }
  //   console.log("onMarkerDragEnd: ", JSON.stringify(loc), address);
  // };
  // const onMarkerLoad: MarkerProps["onLoad"] = (marker) => {
  //   console.log("Marker Position: ", JSON.stringify(marker.getPosition()));
  // };

  return (
    <Stack spacing={4}>
      <Typography variant="h6" gutterBottom>
        Planned location
      </Typography>
      {isLoaded ? (
        <>
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <Input
              type="text"
              placeholder="Planting location address"
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Autocomplete>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            options={mapOptions}
            zoom={13}
            center={mapMarker}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
          >
            <MarkerF
              key={"marker1"}
              // onDragEnd={onMarkerDragEnd}
              position={mapMarker}
              // draggable={true}
              // clickable={true}
              title={
                "Designated planting location, drag the marker to reposition"
              }
            />
          </GoogleMap>
        </>
      ) : null}
    </Stack>
  );
};

export default TreeMap;
