import React, { FC } from "react";
import {
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  SwitchProps,
} from "@mui/material";
import TreeSelector from "./TreeSelector";
import { Tree } from "../api/airtable";

interface Props {
  tree: Tree | null;
  complyAppropriateSpecies: boolean;
  complyMinContainerSize: boolean;
  complyWithStandard: boolean;
  handleAttributesChanged: (
    attribute: string,
    value: boolean | Tree | null
  ) => void;
}
const TreeAttributesForm: FC<Props> = (props) => {
  const onCb1Changed: SwitchProps["onChange"] = (event) => {
    if (props.handleAttributesChanged) {
      props.handleAttributesChanged(
        "complyAppropriateSpecies",
        event.target.checked
      );
    }
  };

  const onCb2Changed: SwitchProps["onChange"] = (event) => {
    if (props.handleAttributesChanged) {
      props.handleAttributesChanged(
        "complyMinContainerSize",
        event.target.checked
      );
    }
  };

  const onCb3Changed: SwitchProps["onChange"] = (event) => {
    if (props.handleAttributesChanged) {
      props.handleAttributesChanged("complyWithStandard", event.target.checked);
    }
  };

  function onPlantSelectionChanged(plant: Tree | null) {
    if (props.handleAttributesChanged) {
      props.handleAttributesChanged("tree", plant);
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={4}>
        <TreeSelector
          tree={props.tree}
          onPlantSelectionChanged={onPlantSelectionChanged}
        />
        <Card sx={{ p: 4 }} color="info">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.complyAppropriateSpecies}
                  onChange={onCb1Changed}
                />
              }
              label="The species must be appropriate for the planting site location (consider water needs, size at maturity, and maintenance needs when making your decision)"
            />
            <Divider sx={{ my: 3 }} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.complyMinContainerSize}
                  onChange={onCb2Changed}
                />
              }
              label="Minimum container size for all new trees is 15 gallons"
            />
            <Divider sx={{ my: 3 }} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.complyWithStandard}
                  onChange={onCb3Changed}
                />
              }
              label="Every tree planted must comply with the American Standard for Nursery Stock (ANSI Z160.1)"
            />
          </FormGroup>
        </Card>
      </Stack>
    </React.Fragment>
  );
};

export default TreeAttributesForm;
